<template>
  <div id="admin-article-list" class="admin-contents">
    <div class="admin-contents-title admin-article-list-title">文章列表</div>
    <div class="admin-contents-wrap admin-article-list-wrap">
      <div class="articles-selector">
        <el-input
          class="articles-title-query"
          size="medium"
          placeholder="文章标题"
          v-model="articleTitle"
          clearable>
        </el-input>
        <el-select
          size="medium"
          v-model="articleStatusValue"
          clearable
          placeholder="文章状态">
          <el-option
            v-for="item in articleTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select
          size="medium"
          v-model="sortTypeValue"
          clearable
          placeholder="排序方式">
          <el-option
            v-for="item in sortTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
          size="medium"
          v-model="articlesTime"
          value-format="yyyy-MM-dd"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="articles-selector">
        <el-select
          size="medium"
          v-model="articlesCategory"
          @focus="getAllCategories"
          filterable
          clearable
          placeholder="分类">
          <el-option
            v-for="item in articlesCategories"
            :key="item.categoryId"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        <el-select
          size="medium"
          v-model="articlesTag"
          @focus="getAllTags"
          filterable
          clearable
          placeholder="标签">
          <el-option
            v-for="item in articlesTags"
            :key="item.tagId"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        <el-button
          size="medium"
          type="primary"
          @click="getArticles"
          icon="el-icon-search">
          查询文章
        </el-button>
        <el-button
          size="medium"
          type="danger"
          @click="deleteArticle"
          icon="el-icon-delete">
          删除文章
        </el-button>
      </div>
      <div class="articles-table">
        <el-table
          ref="multipleTable"
          :data="articles"
          tooltip-effect="dark"
          border
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="45">
          </el-table-column>
          <el-table-column
            prop="title"
            label="标题"
            width="400">
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="120">
            <template slot-scope="scope">
              {{ statusLabel[scope.row.status] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="pageviews"
            label="浏览量"
            width="100">
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="155">
            <template slot-scope="scope">
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :open-delay.number="200">
                <p slot="reference">{{ scope.row.timestamp }}</p>
                <slot>
                  发布时间：{{ scope.row.publishTime || '未发布' }}
                  <br>
                  最后编辑时间：{{ scope.row.modifiedTime || '未修改' }}
                </slot>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="category"
            label="分类"
            width="150">
          </el-table-column>
          <el-table-column
            prop="tags"
            label="标签">
            <template slot-scope="scope">
              {{ JSON.parse(scope.row.tags).toString() }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="150">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="toArticle(scope.row.articleId)">
                编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDeleteArticle(scope.row.articleId)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="articles-pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="articlesCurrentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="articlesPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="articlesTotal">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'admin-article-list',
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      articles: [],
      articlesDeleteSelection: [],
      articlesCurrentPage: 1,
      articlesPageSize: 10,
      articlesTotal: 0,
      articleTitle: '',
      articleStatusValue: null,
      articleTypeOptions: [
        { value: '0', label: '草稿' },
        { value: '1', label: '发布' }
      ],
      sortTypeValue: null,
      sortTypeOptions: [
        { value: '1', label: '最新创建' },
        { value: '2', label: '最先创建' },
        { value: '3', label: '阅读最多' }
      ],
      articlesTime: [],
      articlesCategory: '',
      articlesCategories: [],
      articlesTag: '',
      articlesTags: [],
      statusLabel: {
        0: '草稿',
        1: '发布'
      }
    }
  },
  created () {
    this.getArticles()
  },
  methods: {
    handleDeleteArticle (articleId) {
      this.articlesDeleteSelection = [articleId]
      this.deleteArticle()
    },
    deleteArticle () {
      if (this.articlesDeleteSelection.length > 0) {
        this.$confirm('此操作将删除该文章, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$auth_http({
            url: this.$api.ADMIN_ARTICLES_URL,
            method: 'DELETE',
            params: {
              articleId: JSON.stringify(this.articlesDeleteSelection)
            }
          }).then(response => {
            if (response.status === 'success') {
              this.$message.success('文章删除成功')
              this.getArticles()
              this.articlesDeleteSelection = []
            } else {
              this.$message.error(response.message)
            }
          }).catch(error => {
            this.$message.error('文章删除失败，' + error.toString())
          })
        })
      }
    },
    toArticle (articleId) {
      this.$router.push({
        name: 'AdminArticleEditor',
        query: { articleId: articleId }
      })
    },
    handleSelectionChange (val) {
      this.articlesDeleteSelection = []
      for (const item of val) {
        this.articlesDeleteSelection.push(item.articleId)
      }
    },
    handleSizeChange (val) {
      this.articlesPageSize = val
      this.getArticles()
    },
    handleCurrentChange (val) {
      this.articlesCurrentPage = val
      this.getArticles()
    },
    getArticles () {
      this.articles = []
      const queryData = {
        page: this.articlesCurrentPage,
        pageSize: this.articlesPageSize
      }
      if (this.articlesTime) {
        queryData.start = this.articlesTime[0]
        queryData.end = this.articlesTime[1]
      }
      if (this.articleTitle) queryData.title = this.articleTitle
      if (this.articleStatusValue) queryData.status = this.articleStatusValue
      if (this.sortTypeValue) queryData.sort = this.sortTypeValue
      if (this.articlesCategory) queryData.category = this.articlesCategory
      if (this.articlesTag) queryData.tag = this.articlesTag
      this.$auth_http({
        url: this.$api.ADMIN_ARTICLES_URL,
        method: 'GET',
        params: queryData
      }).then(response => {
        this.articlesTotal = response.data.total
        for (const item of response.data.result) {
          this.articles.push({
            articleId: item.articleId,
            title: item.title,
            status: item.status,
            category: item.category,
            tags: item.tags,
            pageviews: item.pageviews,
            timestamp: item.timestamp,
            publishTime: item.publishTime,
            modifiedTime: item.modifiedTime
          })
        }
      }).catch(error => {
        this.$message.error('获取文章列表失败：' + error.toString() + '，请重试')
      })
    },
    getAllCategories () {
      if (this.articlesCategories instanceof Array && this.articlesCategories.length === 0) {
        this.articlesCategories = []
        this.$auth_http({
          url: this.$api.ADMIN_CATEGORIES_URL,
          method: 'GET'
        }).then(response => {
          for (const item of response.data.result) this.articlesCategories.push(item)
        }).catch(error => {
          this.$message.error('获取分类失败：' + error.toString() + '，请重试')
        })
      }
    },
    getAllTags () {
      if (this.articlesTags instanceof Array && this.articlesTags.length === 0) {
        this.articlesTags = []
        this.$auth_http({
          url: this.$api.ADMIN_TAGS_URL,
          method: 'GET'
        }).then(response => {
          for (const item of response.data.result) this.articlesTags.push(item)
        }).catch(error => {
          this.$message.error('获取标签失败：' + error.toString() + '，请重试')
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#admin-article-list
  .admin-article-list-wrap
    .articles-selector
      margin-bottom: 20px
      display: inline-flex
      .articles-title-query
        width: 222px
      .el-input
        margin-right: 10px
      .el-select
        margin-right: 10px
      .el-date-editor
        width: 410px
        margin-right: 10px
      &:first-child
        margin-bottom: 8px
  .articles-pagination
    width: 100%
    margin: 20px
    text-align: center
</style>
